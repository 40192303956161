.bays {
  display: grid;
  grid-gap: 0.2em;
  grid-template-columns: repeat(auto-fit, minmax(70px, 70px));
  row-gap: 1em;
  padding: 1em;
}

.baysLayoutShape {
  position: absolute;
  stroke: #dadada;
  stroke-width: 8;
  fill: none;
  z-index: -1;
}

.baysLayoutFloorLabel {
  font-size: 2em;
  left: 700px;
  transform: translateY(50px);
  position: absolute;
}

.baysLayoutContainer {
  position: fixed;
  width: 100%;
  height: 170px;
  margin-top: 60px;
  min-width: 1600px;
  max-width: 1600px;
}

.baysLayoutLeft {
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: repeat(auto-fit, minmax(40px, 40px));
  padding: 0.5em;
  left: 0;
  width: 30%;
  height: 70px;
  transform: rotate(20deg);
  position: fixed;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}
.baysLayoutCenter {
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: repeat(auto-fit, minmax(40px, 40px));
  padding: 0.5em;
  left: 30%;
  width: 40%;
  height: 70px;
  position: absolute;
  justify-content: space-evenly;
  top: 55%;
}

.baysLayoutRight {
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: repeat(auto-fit, minmax(40px, 40px));
  padding: 0.5em;
  left: 70%;
  height: 70px;
  width: 30%;
  transform: rotate(-20deg);
  position: absolute;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.facilityInfo {
  width: 100%;
  margin: auto;
  padding: 10px;
  flex: 1;
}

.time {
  justify-self: center;
  margin: 0 auto;
  color: black;
  display: grid;
  grid-gap: 1em;
  flex: 1;
  font-size: 1.5em;
}

.facilityName {
  float: right;
  justify-self: center;
  margin: 0 auto;
  color: white;
  grid-gap: 1em;
  place-items: left;
  font-size: 2em;
}

.legend {
  margin-top: 15px;
  margin-bottom: 15px;
}
