.bay {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 20%;
  height: 50px;
  width: 50px;
  position: relative;
  box-shadow: "none";
}

.bayOutOfTime {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
.timeInfo {
  width: 100%;
  margin: auto;
}

.timeLeft {
  font-size: 1em;
  justify-self: center;
  margin: 0 auto;
  display: grid;
  grid-gap: 1em;
  place-items: end;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.timeIcon {
  color: white;
  float: left;
  justify-self: center;
  margin: 0 auto;
  display: grid;
  grid-gap: 1em;
  place-items: left;
}

.bayName {
  text-align: center;
  font: normal normal 400 18px/23px Montserrat;
  letter-spacing: 0;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom:0;
  position: relative;
  z-index: 1;
  /*top: 15px;*/
  /*left: 0;*/
  /*bottom: 0;*/
  /*right: 0;*/
  /*position: absolute;*/
  /*margin: auto;*/
  /*color: white;*/
  /*display: grid;*/
  /*font-size: 2em;*/
}

.bayStatusLabel {
  position: absolute;
  margin: auto;
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
}

.bayStatusLabelExpired {
  position: absolute;
  margin: auto;
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
}

.bayOutOfTimeIcon {
  position: absolute;
  margin: auto;
  position: absolute;
  top: 17px;
  left: 0px;
  bottom: 0;
  right: 0;
  color: red;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.available {
  background: #C1C1C1;
}

.inuse {
  background: #3fdb3f;
}

.dontassign {
  background: #8b008b;
}

.reserved {
  background: #fafa55;
}

.bayLayoutItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 20%;
  height: 40px;
  width: 40px;
  position: relative;
  box-shadow: "none";
}

.bayLayoutItemName {
  top: 8px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  color: white;
  display: grid;
  font-size: 1em;
}
